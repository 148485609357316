// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-app-js": () => import("./../../../src/pages/app.js" /* webpackChunkName: "component---src-pages-app-js" */),
  "component---src-pages-en-app-js": () => import("./../../../src/pages/enApp.js" /* webpackChunkName: "component---src-pages-en-app-js" */),
  "component---src-pages-en-js": () => import("./../../../src/pages/EN.js" /* webpackChunkName: "component---src-pages-en-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-about-js": () => import("./../../../src/templates/about.js" /* webpackChunkName: "component---src-templates-about-js" */),
  "component---src-templates-news-item-js": () => import("./../../../src/templates/newsItem.js" /* webpackChunkName: "component---src-templates-news-item-js" */),
  "component---src-templates-news-page-js": () => import("./../../../src/templates/newsPage.js" /* webpackChunkName: "component---src-templates-news-page-js" */),
  "component---src-templates-organizations-js": () => import("./../../../src/templates/organizations.js" /* webpackChunkName: "component---src-templates-organizations-js" */)
}

